import { default as indexY5Ny83IJl7Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/2fa/index.vue?macro=true";
import { default as indexEg47hzBIZEMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/advisors-metrics/index.vue?macro=true";
import { default as countersignxF468bk52nMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/amended-pledges/[id]/countersign.vue?macro=true";
import { default as indexquLjNQi5bJMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/amended-pledges/[id]/index.vue?macro=true";
import { default as indexvUiFYwtHE3Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/amended-pledges/index.vue?macro=true";
import { default as _91id_93Yss1e8Yu4WMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/assistants/[id].vue?macro=true";
import { default as indexdNY8DGfBpwMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/assistants/index.vue?macro=true";
import { default as _91id_935kG9ieVG72Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/audit-logs/[id].vue?macro=true";
import { default as index2Cj19LEknmMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/audit-logs/index.vue?macro=true";
import { default as _91id_93ckIl4qqogYMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/bank-reconciliation/[id].vue?macro=true";
import { default as indexOBwgeXeQWgMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/bank-reconciliation/index.vue?macro=true";
import { default as indexKxo8gIqhSRMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/case-reporting/index.vue?macro=true";
import { default as _91id_93g28Z6RfmhSMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/charity-categories/[id].vue?macro=true";
import { default as index3hcyHNApYyMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/charity-categories/index.vue?macro=true";
import { default as _91id_931sy2GPaLRvMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/chats/[id].vue?macro=true";
import { default as index7X2qLIYWC2Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/chats/index.vue?macro=true";
import { default as _91id_9346pk727ws7Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/daf-statements/[id].vue?macro=true";
import { default as indexSS7bWQuPRGMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/daf-statements/index.vue?macro=true";
import { default as indexq13h1QKYueMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/forecasted-impact-page-metrics/index.vue?macro=true";
import { default as indexbA3doqd1UfMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/giving-recommendations-endorsements/index.vue?macro=true";
import { default as _91id_93tog9sTshJQMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/giving-recommendations/[id].vue?macro=true";
import { default as indexezfcBaujDcMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/giving-recommendations/index.vue?macro=true";
import { default as index5OIQwW7y2HMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/grants/index.vue?macro=true";
import { default as indextuJcs3h1A5Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/home-metrics/index.vue?macro=true";
import { default as _91id_93j3dpEgnvs7Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/how-we-measure-impact/[id].vue?macro=true";
import { default as indexLyCIsAL10JMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/how-we-measure-impact/index.vue?macro=true";
import { default as indexyMPt7UysjqMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/index.vue?macro=true";
import { default as indexYFRRT4UhrDMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/kr-dashboard/index.vue?macro=true";
import { default as indexByCfSm9rhXMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/login-metrics/index.vue?macro=true";
import { default as _91id_93anRlKsShEcMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/match-campaign-categories/[id].vue?macro=true";
import { default as indexv5ehSEXVKwMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/match-campaign-categories/index.vue?macro=true";
import { default as _91id_93our0PG7Hw9Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/match-campaigns/[id].vue?macro=true";
import { default as indexZRT3IiGtAEMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/match-campaigns/index.vue?macro=true";
import { default as indexXjYEgGyebSMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/member-interactions/index.vue?macro=true";
import { default as indexFuTBTW2SVTMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/my-daf-metrics/index.vue?macro=true";
import { default as indexRTd46E836xMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/my-giving-page-metrics/index.vue?macro=true";
import { default as _91id_93nlo3O4mIIfMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/news/[id].vue?macro=true";
import { default as index4vvG0Wx9ihMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/news/index.vue?macro=true";
import { default as index8L1bj8soh4Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/onboarding-metrics/index.vue?macro=true";
import { default as indexbNY1gfbuGBMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/our-recommendations-page-metrics/index.vue?macro=true";
import { default as indexoS0C7PSOPBMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/pledge-metrics/index.vue?macro=true";
import { default as countersignHkWdFDN7edMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/pledges/[id]/countersign.vue?macro=true";
import { default as indexq7bOG90WUjMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/pledges/[id]/index.vue?macro=true";
import { default as index5jrWwjyC2QMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/pledges/index.vue?macro=true";
import { default as indexpRDM55PGEDMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/settings-page-metrics/index.vue?macro=true";
import { default as indexMnWgCdvHg0Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/settings/index.vue?macro=true";
import { default as signinN69SQ2BUjZMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/signin.vue?macro=true";
import { default as _91id_93ll1q76GrioMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/target-audiences/[id].vue?macro=true";
import { default as index3KnJ1gHbfNMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/target-audiences/index.vue?macro=true";
import { default as add_45pledge0WicOVXZXnMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/users/[id]/add-pledge.vue?macro=true";
import { default as indexigKWCkrRu8Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/users/[id]/index.vue?macro=true";
import { default as indexSPVlqhWZluMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/users/index.vue?macro=true";
import { default as newC8fN3gICkgMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/users/new.vue?macro=true";
export default [
  {
    name: indexY5Ny83IJl7Meta?.name ?? "2fa",
    path: indexY5Ny83IJl7Meta?.path ?? "/2fa",
    meta: indexY5Ny83IJl7Meta || {},
    alias: indexY5Ny83IJl7Meta?.alias || [],
    redirect: indexY5Ny83IJl7Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/2fa/index.vue").then(m => m.default || m)
  },
  {
    name: indexEg47hzBIZEMeta?.name ?? "advisors-metrics",
    path: indexEg47hzBIZEMeta?.path ?? "/advisors-metrics",
    meta: indexEg47hzBIZEMeta || {},
    alias: indexEg47hzBIZEMeta?.alias || [],
    redirect: indexEg47hzBIZEMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/advisors-metrics/index.vue").then(m => m.default || m)
  },
  {
    name: countersignxF468bk52nMeta?.name ?? "amended-pledges-id-countersign",
    path: countersignxF468bk52nMeta?.path ?? "/amended-pledges/:id()/countersign",
    meta: countersignxF468bk52nMeta || {},
    alias: countersignxF468bk52nMeta?.alias || [],
    redirect: countersignxF468bk52nMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/amended-pledges/[id]/countersign.vue").then(m => m.default || m)
  },
  {
    name: indexquLjNQi5bJMeta?.name ?? "amended-pledges-id",
    path: indexquLjNQi5bJMeta?.path ?? "/amended-pledges/:id()",
    meta: indexquLjNQi5bJMeta || {},
    alias: indexquLjNQi5bJMeta?.alias || [],
    redirect: indexquLjNQi5bJMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/amended-pledges/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvUiFYwtHE3Meta?.name ?? "amended-pledges",
    path: indexvUiFYwtHE3Meta?.path ?? "/amended-pledges",
    meta: indexvUiFYwtHE3Meta || {},
    alias: indexvUiFYwtHE3Meta?.alias || [],
    redirect: indexvUiFYwtHE3Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/amended-pledges/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Yss1e8Yu4WMeta?.name ?? "assistants-id",
    path: _91id_93Yss1e8Yu4WMeta?.path ?? "/assistants/:id()",
    meta: _91id_93Yss1e8Yu4WMeta || {},
    alias: _91id_93Yss1e8Yu4WMeta?.alias || [],
    redirect: _91id_93Yss1e8Yu4WMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/assistants/[id].vue").then(m => m.default || m)
  },
  {
    name: indexdNY8DGfBpwMeta?.name ?? "assistants",
    path: indexdNY8DGfBpwMeta?.path ?? "/assistants",
    meta: indexdNY8DGfBpwMeta || {},
    alias: indexdNY8DGfBpwMeta?.alias || [],
    redirect: indexdNY8DGfBpwMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/assistants/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_935kG9ieVG72Meta?.name ?? "audit-logs-id",
    path: _91id_935kG9ieVG72Meta?.path ?? "/audit-logs/:id()",
    meta: _91id_935kG9ieVG72Meta || {},
    alias: _91id_935kG9ieVG72Meta?.alias || [],
    redirect: _91id_935kG9ieVG72Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/audit-logs/[id].vue").then(m => m.default || m)
  },
  {
    name: index2Cj19LEknmMeta?.name ?? "audit-logs",
    path: index2Cj19LEknmMeta?.path ?? "/audit-logs",
    meta: index2Cj19LEknmMeta || {},
    alias: index2Cj19LEknmMeta?.alias || [],
    redirect: index2Cj19LEknmMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/audit-logs/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ckIl4qqogYMeta?.name ?? "bank-reconciliation-id",
    path: _91id_93ckIl4qqogYMeta?.path ?? "/bank-reconciliation/:id()",
    meta: _91id_93ckIl4qqogYMeta || {},
    alias: _91id_93ckIl4qqogYMeta?.alias || [],
    redirect: _91id_93ckIl4qqogYMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/bank-reconciliation/[id].vue").then(m => m.default || m)
  },
  {
    name: indexOBwgeXeQWgMeta?.name ?? "bank-reconciliation",
    path: indexOBwgeXeQWgMeta?.path ?? "/bank-reconciliation",
    meta: indexOBwgeXeQWgMeta || {},
    alias: indexOBwgeXeQWgMeta?.alias || [],
    redirect: indexOBwgeXeQWgMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/bank-reconciliation/index.vue").then(m => m.default || m)
  },
  {
    name: indexKxo8gIqhSRMeta?.name ?? "case-reporting",
    path: indexKxo8gIqhSRMeta?.path ?? "/case-reporting",
    meta: indexKxo8gIqhSRMeta || {},
    alias: indexKxo8gIqhSRMeta?.alias || [],
    redirect: indexKxo8gIqhSRMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/case-reporting/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93g28Z6RfmhSMeta?.name ?? "charity-categories-id",
    path: _91id_93g28Z6RfmhSMeta?.path ?? "/charity-categories/:id()",
    meta: _91id_93g28Z6RfmhSMeta || {},
    alias: _91id_93g28Z6RfmhSMeta?.alias || [],
    redirect: _91id_93g28Z6RfmhSMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/charity-categories/[id].vue").then(m => m.default || m)
  },
  {
    name: index3hcyHNApYyMeta?.name ?? "charity-categories",
    path: index3hcyHNApYyMeta?.path ?? "/charity-categories",
    meta: index3hcyHNApYyMeta || {},
    alias: index3hcyHNApYyMeta?.alias || [],
    redirect: index3hcyHNApYyMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/charity-categories/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_931sy2GPaLRvMeta?.name ?? "chats-id",
    path: _91id_931sy2GPaLRvMeta?.path ?? "/chats/:id()",
    meta: _91id_931sy2GPaLRvMeta || {},
    alias: _91id_931sy2GPaLRvMeta?.alias || [],
    redirect: _91id_931sy2GPaLRvMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/chats/[id].vue").then(m => m.default || m)
  },
  {
    name: index7X2qLIYWC2Meta?.name ?? "chats",
    path: index7X2qLIYWC2Meta?.path ?? "/chats",
    meta: index7X2qLIYWC2Meta || {},
    alias: index7X2qLIYWC2Meta?.alias || [],
    redirect: index7X2qLIYWC2Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/chats/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_9346pk727ws7Meta?.name ?? "daf-statements-id",
    path: _91id_9346pk727ws7Meta?.path ?? "/daf-statements/:id()",
    meta: _91id_9346pk727ws7Meta || {},
    alias: _91id_9346pk727ws7Meta?.alias || [],
    redirect: _91id_9346pk727ws7Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/daf-statements/[id].vue").then(m => m.default || m)
  },
  {
    name: indexSS7bWQuPRGMeta?.name ?? "daf-statements",
    path: indexSS7bWQuPRGMeta?.path ?? "/daf-statements",
    meta: indexSS7bWQuPRGMeta || {},
    alias: indexSS7bWQuPRGMeta?.alias || [],
    redirect: indexSS7bWQuPRGMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/daf-statements/index.vue").then(m => m.default || m)
  },
  {
    name: indexq13h1QKYueMeta?.name ?? "forecasted-impact-page-metrics",
    path: indexq13h1QKYueMeta?.path ?? "/forecasted-impact-page-metrics",
    meta: indexq13h1QKYueMeta || {},
    alias: indexq13h1QKYueMeta?.alias || [],
    redirect: indexq13h1QKYueMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/forecasted-impact-page-metrics/index.vue").then(m => m.default || m)
  },
  {
    name: indexbA3doqd1UfMeta?.name ?? "giving-recommendations-endorsements",
    path: indexbA3doqd1UfMeta?.path ?? "/giving-recommendations-endorsements",
    meta: indexbA3doqd1UfMeta || {},
    alias: indexbA3doqd1UfMeta?.alias || [],
    redirect: indexbA3doqd1UfMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/giving-recommendations-endorsements/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93tog9sTshJQMeta?.name ?? "giving-recommendations-id",
    path: _91id_93tog9sTshJQMeta?.path ?? "/giving-recommendations/:id()",
    meta: _91id_93tog9sTshJQMeta || {},
    alias: _91id_93tog9sTshJQMeta?.alias || [],
    redirect: _91id_93tog9sTshJQMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/giving-recommendations/[id].vue").then(m => m.default || m)
  },
  {
    name: indexezfcBaujDcMeta?.name ?? "giving-recommendations",
    path: indexezfcBaujDcMeta?.path ?? "/giving-recommendations",
    meta: indexezfcBaujDcMeta || {},
    alias: indexezfcBaujDcMeta?.alias || [],
    redirect: indexezfcBaujDcMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/giving-recommendations/index.vue").then(m => m.default || m)
  },
  {
    name: index5OIQwW7y2HMeta?.name ?? "grants",
    path: index5OIQwW7y2HMeta?.path ?? "/grants",
    meta: index5OIQwW7y2HMeta || {},
    alias: index5OIQwW7y2HMeta?.alias || [],
    redirect: index5OIQwW7y2HMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/grants/index.vue").then(m => m.default || m)
  },
  {
    name: indextuJcs3h1A5Meta?.name ?? "home-metrics",
    path: indextuJcs3h1A5Meta?.path ?? "/home-metrics",
    meta: indextuJcs3h1A5Meta || {},
    alias: indextuJcs3h1A5Meta?.alias || [],
    redirect: indextuJcs3h1A5Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/home-metrics/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93j3dpEgnvs7Meta?.name ?? "how-we-measure-impact-id",
    path: _91id_93j3dpEgnvs7Meta?.path ?? "/how-we-measure-impact/:id()",
    meta: _91id_93j3dpEgnvs7Meta || {},
    alias: _91id_93j3dpEgnvs7Meta?.alias || [],
    redirect: _91id_93j3dpEgnvs7Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/how-we-measure-impact/[id].vue").then(m => m.default || m)
  },
  {
    name: indexLyCIsAL10JMeta?.name ?? "how-we-measure-impact",
    path: indexLyCIsAL10JMeta?.path ?? "/how-we-measure-impact",
    meta: indexLyCIsAL10JMeta || {},
    alias: indexLyCIsAL10JMeta?.alias || [],
    redirect: indexLyCIsAL10JMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/how-we-measure-impact/index.vue").then(m => m.default || m)
  },
  {
    name: indexyMPt7UysjqMeta?.name ?? "index",
    path: indexyMPt7UysjqMeta?.path ?? "/",
    meta: indexyMPt7UysjqMeta || {},
    alias: indexyMPt7UysjqMeta?.alias || [],
    redirect: indexyMPt7UysjqMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexYFRRT4UhrDMeta?.name ?? "kr-dashboard",
    path: indexYFRRT4UhrDMeta?.path ?? "/kr-dashboard",
    meta: indexYFRRT4UhrDMeta || {},
    alias: indexYFRRT4UhrDMeta?.alias || [],
    redirect: indexYFRRT4UhrDMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/kr-dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexByCfSm9rhXMeta?.name ?? "login-metrics",
    path: indexByCfSm9rhXMeta?.path ?? "/login-metrics",
    meta: indexByCfSm9rhXMeta || {},
    alias: indexByCfSm9rhXMeta?.alias || [],
    redirect: indexByCfSm9rhXMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/login-metrics/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93anRlKsShEcMeta?.name ?? "match-campaign-categories-id",
    path: _91id_93anRlKsShEcMeta?.path ?? "/match-campaign-categories/:id()",
    meta: _91id_93anRlKsShEcMeta || {},
    alias: _91id_93anRlKsShEcMeta?.alias || [],
    redirect: _91id_93anRlKsShEcMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/match-campaign-categories/[id].vue").then(m => m.default || m)
  },
  {
    name: indexv5ehSEXVKwMeta?.name ?? "match-campaign-categories",
    path: indexv5ehSEXVKwMeta?.path ?? "/match-campaign-categories",
    meta: indexv5ehSEXVKwMeta || {},
    alias: indexv5ehSEXVKwMeta?.alias || [],
    redirect: indexv5ehSEXVKwMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/match-campaign-categories/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93our0PG7Hw9Meta?.name ?? "match-campaigns-id",
    path: _91id_93our0PG7Hw9Meta?.path ?? "/match-campaigns/:id()",
    meta: _91id_93our0PG7Hw9Meta || {},
    alias: _91id_93our0PG7Hw9Meta?.alias || [],
    redirect: _91id_93our0PG7Hw9Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/match-campaigns/[id].vue").then(m => m.default || m)
  },
  {
    name: indexZRT3IiGtAEMeta?.name ?? "match-campaigns",
    path: indexZRT3IiGtAEMeta?.path ?? "/match-campaigns",
    meta: indexZRT3IiGtAEMeta || {},
    alias: indexZRT3IiGtAEMeta?.alias || [],
    redirect: indexZRT3IiGtAEMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/match-campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: indexXjYEgGyebSMeta?.name ?? "member-interactions",
    path: indexXjYEgGyebSMeta?.path ?? "/member-interactions",
    meta: indexXjYEgGyebSMeta || {},
    alias: indexXjYEgGyebSMeta?.alias || [],
    redirect: indexXjYEgGyebSMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/member-interactions/index.vue").then(m => m.default || m)
  },
  {
    name: indexFuTBTW2SVTMeta?.name ?? "my-daf-metrics",
    path: indexFuTBTW2SVTMeta?.path ?? "/my-daf-metrics",
    meta: indexFuTBTW2SVTMeta || {},
    alias: indexFuTBTW2SVTMeta?.alias || [],
    redirect: indexFuTBTW2SVTMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/my-daf-metrics/index.vue").then(m => m.default || m)
  },
  {
    name: indexRTd46E836xMeta?.name ?? "my-giving-page-metrics",
    path: indexRTd46E836xMeta?.path ?? "/my-giving-page-metrics",
    meta: indexRTd46E836xMeta || {},
    alias: indexRTd46E836xMeta?.alias || [],
    redirect: indexRTd46E836xMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/my-giving-page-metrics/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93nlo3O4mIIfMeta?.name ?? "news-id",
    path: _91id_93nlo3O4mIIfMeta?.path ?? "/news/:id()",
    meta: _91id_93nlo3O4mIIfMeta || {},
    alias: _91id_93nlo3O4mIIfMeta?.alias || [],
    redirect: _91id_93nlo3O4mIIfMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: index4vvG0Wx9ihMeta?.name ?? "news",
    path: index4vvG0Wx9ihMeta?.path ?? "/news",
    meta: index4vvG0Wx9ihMeta || {},
    alias: index4vvG0Wx9ihMeta?.alias || [],
    redirect: index4vvG0Wx9ihMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: index8L1bj8soh4Meta?.name ?? "onboarding-metrics",
    path: index8L1bj8soh4Meta?.path ?? "/onboarding-metrics",
    meta: index8L1bj8soh4Meta || {},
    alias: index8L1bj8soh4Meta?.alias || [],
    redirect: index8L1bj8soh4Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/onboarding-metrics/index.vue").then(m => m.default || m)
  },
  {
    name: indexbNY1gfbuGBMeta?.name ?? "our-recommendations-page-metrics",
    path: indexbNY1gfbuGBMeta?.path ?? "/our-recommendations-page-metrics",
    meta: indexbNY1gfbuGBMeta || {},
    alias: indexbNY1gfbuGBMeta?.alias || [],
    redirect: indexbNY1gfbuGBMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/our-recommendations-page-metrics/index.vue").then(m => m.default || m)
  },
  {
    name: indexoS0C7PSOPBMeta?.name ?? "pledge-metrics",
    path: indexoS0C7PSOPBMeta?.path ?? "/pledge-metrics",
    meta: indexoS0C7PSOPBMeta || {},
    alias: indexoS0C7PSOPBMeta?.alias || [],
    redirect: indexoS0C7PSOPBMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/pledge-metrics/index.vue").then(m => m.default || m)
  },
  {
    name: countersignHkWdFDN7edMeta?.name ?? "pledges-id-countersign",
    path: countersignHkWdFDN7edMeta?.path ?? "/pledges/:id()/countersign",
    meta: countersignHkWdFDN7edMeta || {},
    alias: countersignHkWdFDN7edMeta?.alias || [],
    redirect: countersignHkWdFDN7edMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/pledges/[id]/countersign.vue").then(m => m.default || m)
  },
  {
    name: indexq7bOG90WUjMeta?.name ?? "pledges-id",
    path: indexq7bOG90WUjMeta?.path ?? "/pledges/:id()",
    meta: indexq7bOG90WUjMeta || {},
    alias: indexq7bOG90WUjMeta?.alias || [],
    redirect: indexq7bOG90WUjMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/pledges/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index5jrWwjyC2QMeta?.name ?? "pledges",
    path: index5jrWwjyC2QMeta?.path ?? "/pledges",
    meta: index5jrWwjyC2QMeta || {},
    alias: index5jrWwjyC2QMeta?.alias || [],
    redirect: index5jrWwjyC2QMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/pledges/index.vue").then(m => m.default || m)
  },
  {
    name: indexpRDM55PGEDMeta?.name ?? "settings-page-metrics",
    path: indexpRDM55PGEDMeta?.path ?? "/settings-page-metrics",
    meta: indexpRDM55PGEDMeta || {},
    alias: indexpRDM55PGEDMeta?.alias || [],
    redirect: indexpRDM55PGEDMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/settings-page-metrics/index.vue").then(m => m.default || m)
  },
  {
    name: indexMnWgCdvHg0Meta?.name ?? "settings",
    path: indexMnWgCdvHg0Meta?.path ?? "/settings",
    meta: indexMnWgCdvHg0Meta || {},
    alias: indexMnWgCdvHg0Meta?.alias || [],
    redirect: indexMnWgCdvHg0Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: signinN69SQ2BUjZMeta?.name ?? "signin",
    path: signinN69SQ2BUjZMeta?.path ?? "/signin",
    meta: signinN69SQ2BUjZMeta || {},
    alias: signinN69SQ2BUjZMeta?.alias || [],
    redirect: signinN69SQ2BUjZMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ll1q76GrioMeta?.name ?? "target-audiences-id",
    path: _91id_93ll1q76GrioMeta?.path ?? "/target-audiences/:id()",
    meta: _91id_93ll1q76GrioMeta || {},
    alias: _91id_93ll1q76GrioMeta?.alias || [],
    redirect: _91id_93ll1q76GrioMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/target-audiences/[id].vue").then(m => m.default || m)
  },
  {
    name: index3KnJ1gHbfNMeta?.name ?? "target-audiences",
    path: index3KnJ1gHbfNMeta?.path ?? "/target-audiences",
    meta: index3KnJ1gHbfNMeta || {},
    alias: index3KnJ1gHbfNMeta?.alias || [],
    redirect: index3KnJ1gHbfNMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/target-audiences/index.vue").then(m => m.default || m)
  },
  {
    name: add_45pledge0WicOVXZXnMeta?.name ?? "users-id-add-pledge",
    path: add_45pledge0WicOVXZXnMeta?.path ?? "/users/:id()/add-pledge",
    meta: add_45pledge0WicOVXZXnMeta || {},
    alias: add_45pledge0WicOVXZXnMeta?.alias || [],
    redirect: add_45pledge0WicOVXZXnMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/users/[id]/add-pledge.vue").then(m => m.default || m)
  },
  {
    name: indexigKWCkrRu8Meta?.name ?? "users-id",
    path: indexigKWCkrRu8Meta?.path ?? "/users/:id()",
    meta: indexigKWCkrRu8Meta || {},
    alias: indexigKWCkrRu8Meta?.alias || [],
    redirect: indexigKWCkrRu8Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexSPVlqhWZluMeta?.name ?? "users",
    path: indexSPVlqhWZluMeta?.path ?? "/users",
    meta: indexSPVlqhWZluMeta || {},
    alias: indexSPVlqhWZluMeta?.alias || [],
    redirect: indexSPVlqhWZluMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: newC8fN3gICkgMeta?.name ?? "users-new",
    path: newC8fN3gICkgMeta?.path ?? "/users/new",
    meta: newC8fN3gICkgMeta || {},
    alias: newC8fN3gICkgMeta?.alias || [],
    redirect: newC8fN3gICkgMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/users/new.vue").then(m => m.default || m)
  }
]
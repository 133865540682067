import revive_payload_client_YTGlmQWWT5 from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_xKuw1km5sV from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/packages/member-webapp-admin/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/opt/build/repo/packages/member-webapp-admin/.nuxt/floating-vue.mjs";
import formkitPlugin_pZqjah0RUG from "/opt/build/repo/packages/member-webapp-admin/.nuxt/formkitPlugin.mjs";
import plugin_j3xPeZV1re from "/opt/build/repo/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import apexcharts_client_Dd5Ebxj7XB from "/opt/build/repo/packages/member-webapp-admin/plugins/apexcharts.client.ts";
import error_client_aUA8HQSx65 from "/opt/build/repo/packages/member-webapp-admin/plugins/error.client.ts";
import sentry_client_shVUlIjFLk from "/opt/build/repo/packages/member-webapp-admin/plugins/sentry.client.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  check_outdated_build_client_LIYcCMJD18,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  floating_vue_EIcJ7xiw0h,
  formkitPlugin_pZqjah0RUG,
  plugin_j3xPeZV1re,
  chunk_reload_client_SeG0EjL5Ec,
  apexcharts_client_Dd5Ebxj7XB,
  error_client_aUA8HQSx65,
  sentry_client_shVUlIjFLk
]